import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./pages/pc/chat.vue'),
    meta: {
      title: '文件传输助手',
    },
  },
  {
    name: 'helpPage',
    path: '/helpPage',
    component: () => import('./pages/mobile/helpPage.vue'),
    meta: {
      title: '帮助中心',
    },
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {

  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
