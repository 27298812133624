import { createApp } from 'vue'
import App from './App.vue'
import {router} from './router'
const app = createApp(App);
// import {
//   Loading
// } from 'element-ui';

// app.use(Loading.directive);

// app.prototype.$loading = Loading.service;
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
app.use(router).use(Antd);
app.mount('#app')
